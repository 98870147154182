window.onload = function () {
	const $contactForm = document.getElementById("contact-form");

	var currentTab = +0; // Current tab is set to be the first tab (0)
	showTab(currentTab); // Display the current tab

	function showTab(n) {
		let _realN = +n + 1;
		$contactForm.dataset.current = _realN;
		document.body.scrollIntoView();
	}

	const $buttonPrev = document.querySelectorAll(".cf-prev"),
		$buttonNext = document.querySelectorAll(".cf-next");

	$buttonPrev.forEach(function (item) {
		item.addEventListener("click", function (e) {
			nextPrev(-1);
		});
	});
	$buttonNext.forEach(function (item) {
		item.addEventListener("click", function (e) {
			const nextTo = e.target.dataset.to;
			nextPrev(1);
		});
	});

	function nextPrev(n) {
		var x = document.getElementsByClassName("tab");
		if (n == 1 && !validateForm()) return false;

		currentTab = currentTab + n;
		if (currentTab >= x.length) {
			document.getElementById("testForm").submit();
			return false;
		}
		showTab(currentTab);
	}

	function validateForm() {
		var x,
			y,
			i,
			valid = true;
		x = document.getElementsByClassName("tab");
		y = x[currentTab].getElementsByTagName(
			"input:not(#acf-field_60362846348f6)"
		);

		for (i = 0; i < y.length; i++) {
			if (y[i].value == "" && y[i].type != "hidden") {
				y[i].className += " invalid";
				valid = false;
			}
		}

		return valid; // return the valid status
	}
};

const $contactForm = document.querySelector("form#contact-form");

/* ---------------------------------------------------------------- */
/*                      Check for step 4 label                      */
/* ---------------------------------------------------------------- */
const $youAreRadios = jQuery('.acf-field-60197d3a0a1f5 input[type="radio"]');

const checkYouAreRadios = () => {
	if (!$youAreRadios.length) return false;
	let isArtist = document.querySelector(
		'.acf-field-60197d3a0a1f5 input[type="radio"]'
	).checked;
	$contactForm.dataset.artist = isArtist ? "true" : "false";
};

checkYouAreRadios();

$youAreRadios.on("change", checkYouAreRadios);

/* ---------------------------------------------------------------- */
/*                            Name input                            */
/* ---------------------------------------------------------------- */
function emailIsValid(email) {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const $nameInput = document.querySelector("input#acf-field_60197d1f0a1f4"),
	$nameLabel = document.querySelector("label[for='acf-field_60197d680a1f6']"),
	$nameLabelInitial = $nameLabel && $nameLabel.textContent.replace(" *", "");

// email
const $emailInput = document.querySelector("input#acf-field_60197ddc0a1f8"),
	$termsInput = document.querySelector("input#acf-field_60197e110a1fb");

// Step buttons
const $next1 = document.querySelector(".cf-next.cf-next-1"),
	$next2 = document.querySelector(".cf-next.cf-next-2"),
	$next3 = document.querySelector(".cf-next.cf-next-3"),
	$next4 = document.querySelector(".cf-next.cf-next-4");

const $submitWrap =
	$contactForm && $contactForm.querySelector(".acf-form-submit");

if ($contactForm && $submitWrap) {
	let tabFive = $contactForm.querySelector(".tab-5 .wrap");
	tabFive.appendChild($submitWrap);

	const $submitButton = $submitWrap.querySelector("#subBtn");
	$submitButton.classList.add("disabled");

	$emailInput.addEventListener("input", checkSubmitBtn);
	$termsInput.addEventListener("input", checkSubmitBtn);

	function checkSubmitBtn() {
		if (
			$emailInput.value &&
			emailIsValid($emailInput.value) &&
			$termsInput.checked
		) {
			$submitButton.classList.remove("disabled");
		} else {
			$submitButton.classList.add("disabled");
		}
	}
}

if ($nameInput) {
	$next2.classList.add("active");
	$next3.classList.add("active");

	$nameInput.addEventListener("keyup", function () {
		let nameValue = this.value || "",
			labelValue = $nameLabelInitial.replace("%name%", nameValue);

		if (this.value.length > 2) {
			$next1.classList.add("active");
		} else {
			$next1.classList.remove("active");
		}

		$nameLabel.textContent = labelValue;
	});
}

const $textareaInput = document.querySelector("#acf-field_60197da60a1f7"),
	$textCount = document.querySelector("#count-4"),
	$countryInput = document.querySelector("#acf-field_60362846348f6");

if ($textareaInput) {
	$textareaInput.addEventListener("keyup", function () {
		$textCount.innerHTML = $textareaInput.value.length;
		sizeTextarea($textareaInput);
		if ($textareaInput.value.length > 1) {
			$next4.classList.add("active");
		} else {
			$next4.classList.remove("active");
		}
	});
}

function sizeTextarea(el) {
	setTimeout(function () {
		el.style.cssText = "height:auto; padding:0";
		el.style.cssText = "height:" + (+el.scrollHeight + 10) + "px";
	}, 0);
}
